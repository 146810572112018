import React from 'react';

const YooApp = (props) => {
  return (
    <svg className={props.className} width="107" height="17" viewBox="0 0 107 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.26 0.397615L7.97606 10.2232H7.27916L7.7096 9.08255L3.67166 0.397615H0.433105L5.16795 10.1832C5.61889 11.2238 6.31579 11.584 7.44314 11.644L8.52949 14.4456H4.799V16.6669H12.4239L10.4357 11.8241L15.355 0.397615H12.26Z" fill="currentColor"/>
      <path d="M24.2436 0.17749C20.6361 0.17749 16.6802 2.23866 16.6802 6.10085C16.6802 9.74292 20.2262 11.9842 24.1411 11.9842C27.9946 11.9842 31.4791 9.82297 31.4791 6.10085C31.4791 2.07857 27.7076 0.17749 24.2436 0.17749ZM19.7342 6.06083C19.7342 4.11973 21.21 1.89847 24.1616 1.89847C26.8672 1.89847 28.4045 3.77953 28.4045 6.06083C28.4045 8.52223 26.6418 10.3833 24.1001 10.3833C21.538 10.3833 19.7342 8.50222 19.7342 6.06083Z" fill="currentColor"/>
      <path d="M40.9376 0.17749C37.3301 0.17749 33.3741 2.23866 33.3741 6.10085C33.3741 9.74292 36.9201 11.9842 40.8351 11.9842C44.6886 11.9842 48.1731 9.82297 48.1731 6.10085C48.1731 2.07857 44.4016 0.17749 40.9376 0.17749ZM36.4282 6.06083C36.4282 4.11973 37.904 1.89847 40.8556 1.89847C43.5612 1.89847 45.0985 3.77953 45.0985 6.06083C45.0985 8.52223 43.3358 10.3833 40.7941 10.3833C38.232 10.3833 36.4282 8.50222 36.4282 6.06083Z" fill="currentColor"/>
      <path d="M50.2116 10.2432C50.2116 11.2038 50.929 11.8441 51.9949 11.8441C53.0607 11.8441 53.7781 11.2038 53.7781 10.2432C53.7781 9.26265 53.0607 8.62228 51.9949 8.62228C50.929 8.62228 50.2116 9.26265 50.2116 10.2432Z" fill="currentColor"/>
      <path d="M55.6899 8.10199C55.6899 10.5034 58.1496 11.8641 60.6708 11.8641C62.5975 11.8641 65.3646 11.0837 66.7379 8.08198L67.3528 8.40216C67.1479 8.70233 66.7584 9.883 66.7584 10.5234C66.7584 10.9836 67.0454 11.684 67.7013 11.684L68.0907 11.664V11.684H71.6982V9.44275H69.669V5.6606C69.669 1.97851 67.9268 0.397615 64.0323 0.397615H56.9403L56.9608 2.55884H62.9459C65.5901 2.55884 66.5944 3.37931 66.5944 5.38044C66.5944 5.70063 66.6149 6.50108 66.5944 6.86129C65.2006 5.96077 63.1714 4.7801 60.4863 4.7801C57.9856 4.7801 55.6899 5.78067 55.6899 8.10199ZM58.7645 8.14201C58.7645 6.5411 60.7118 6.26094 61.5931 6.26094C63.6633 6.26094 66.0205 7.7618 66.0205 7.7618C66.0205 7.7618 64.7702 10.1431 61.8391 10.1431C60.2813 10.1431 58.7645 9.46276 58.7645 8.14201Z" fill="currentColor"/>
      <path d="M82.5204 11.8641C85.7385 11.8641 88.7516 9.92302 88.7516 6.02081C88.7516 2.15862 85.8205 0.217512 82.6024 0.217512C79.9993 0.217512 77.4166 1.49824 76.4328 3.03911H75.7358L76.6582 1.69835V0.397615H73.5836V16.6669H76.6582V10.2232L75.6949 8.20205H76.3303C77.3551 10.2032 79.5688 11.8641 82.5204 11.8641ZM81.4341 1.9585C84.3037 1.9585 85.677 3.39932 85.677 5.84071C85.677 8.20205 84.3857 9.80295 81.5161 9.80295C78.3595 9.80295 76.6787 7.86185 76.6787 5.72064C76.6787 3.45935 78.5235 1.9585 81.4341 1.9585Z" fill="currentColor"/>
      <path d="M100.335 11.8641C103.553 11.8641 106.566 9.92302 106.566 6.02081C106.566 2.15862 103.635 0.217512 100.417 0.217512C97.8142 0.217512 95.2315 1.49824 94.2477 3.03911H93.5508L94.4731 1.69835V0.397615H91.3986V16.6669H94.4731V10.2232L93.5098 8.20205H94.1452C95.1701 10.2032 97.3837 11.8641 100.335 11.8641ZM99.249 1.9585C102.119 1.9585 103.492 3.39932 103.492 5.84071C103.492 8.20205 102.201 9.80295 99.331 9.80295C96.1744 9.80295 94.4936 7.86185 94.4936 5.72064C94.4936 3.45935 96.3384 1.9585 99.249 1.9585Z" fill="currentColor"/>
    </svg>
  );
};

export default YooApp;