import React from 'react';

const MenuDelivery = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.1428 25.7194C12.1685 25.7194 12.9999 24.8879 12.9999 23.8623C12.9999 22.8366 12.1685 22.0051 11.1428 22.0051C10.1171 22.0051 9.28564 22.8366 9.28564 23.8623C9.28564 24.8879 10.1171 25.7194 11.1428 25.7194Z" fill="currentColor" fillOpacity="0.1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.5002 25.7194C20.5259 25.7194 21.3574 24.8879 21.3574 23.8623C21.3574 22.8366 20.5259 22.0051 19.5002 22.0051C18.4745 22.0051 17.6431 22.8366 17.6431 23.8623C17.6431 24.8879 18.4745 25.7194 19.5002 25.7194Z" fill="currentColor" fillOpacity="0.1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.5629 7.22232C15.2779 6.07747 14.7243 5.01696 13.948 4.12859C13.1716 3.24022 12.1949 2.54953 11.0985 2.1137C10.0022 1.67788 8.81781 1.50945 7.64343 1.62235C6.46905 1.73526 5.33845 2.12626 4.34523 2.76298C3.352 3.39971 2.52471 4.26384 1.93185 5.28385C1.33898 6.30387 0.997592 7.45042 0.935934 8.6286C0.874276 9.80678 1.09413 10.9827 1.57728 12.059C2.06044 13.1354 2.79301 14.0811 3.71435 14.818" fill="currentColor" fillOpacity="0.1"/>
      <path d="M15.5629 7.22232C15.2779 6.07747 14.7243 5.01696 13.948 4.12859C13.1716 3.24022 12.1949 2.54953 11.0985 2.1137C10.0022 1.67788 8.81781 1.50945 7.64343 1.62235C6.46905 1.73526 5.33845 2.12626 4.34523 2.76298C3.352 3.39971 2.52471 4.26384 1.93185 5.28385C1.33898 6.30387 0.997592 7.45042 0.935934 8.6286C0.874276 9.80678 1.09413 10.9827 1.57728 12.059C2.06044 13.1354 2.79301 14.0811 3.71435 14.818" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.35742 9.04231L11.1431 6.25659" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.0713 18.3094V16.4523H9.28557C8.30048 16.4523 7.35574 16.8436 6.65918 17.5402C5.96261 18.2367 5.57129 19.1815 5.57129 20.1666V23.8808" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M25.0713 23.8623V14.5766C25.0713 14.0841 24.8756 13.6117 24.5273 13.2634C24.1791 12.9151 23.7067 12.7195 23.2141 12.7195H13.9284C13.4359 12.7195 12.9635 12.9151 12.6152 13.2634C12.267 13.6117 12.0713 14.0841 12.0713 14.5766V18.2909" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default MenuDelivery;